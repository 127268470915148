
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.populars {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $base-gutter;
  }
  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
  }
  &__pagination {
    margin-top: $base-gutter;
    justify-content: center;
  }
  &__search {
    margin-right: $base-gutter;
    width: 400px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-grayscale-10;
    padding: 10px;
    //cursor: move;
    &:nth-child(odd) {
      background-color: $color-white;
    }
    &--header {
      background-color: $color-grayscale-05 !important;
    }
  }
  &__item-service-name {
    width: 100%;
  }
  &__item-service-id {
    width: 100px;
    flex-shrink: 0;
  }
  &__action {
    width: 48px;
    flex-shrink: 0;
  }
}
